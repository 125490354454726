import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import type {IWishlistItem} from "#sitis/internal/controllers/wish/models/WishlistItem";

type EcommerceActionType = 'detail' | 'click' | 'add' | 'remove';

type EcommerceOptionsType = {
	quantity?: number;
}

export default function useEcommerce () {
  function addEcommerce (actionType: EcommerceActionType, product: IProduct | null | undefined, options?: EcommerceOptionsType) {
    if (window.dataLayer) {
      const ecommerce = {
        currencyCode: 'RUB',
        [actionType]: {
          products: [
            {
              id: product?.id || '',
              name: product?.name || '',
              price: product?.price || '',
              brand: product?.brand?.name || '',
              ...(options)
            }
          ]
        }
      };

      window.dataLayer.push({ ecommerce });
    }
  }

  function addEcommerceProductsList (productsList: IProduct[] | IWishlistItem[], productsLocation?: string) {
    if (window.dataLayer) {
      const dataLayerIndex = window.dataLayer.findIndex((t) => t.ecommerce && t.ecommerce.impressions);
      const newProducts = productsList.map((product, index) => {
        const _product: any = product;
        const brandName = String(_product?.brand?.name || "");
        const productPosition = index + 1 || '';
        return {
          id: product?.id || '',
          name: product?.name || '',
          price: product?.price || '',
          // brand: brandName, // но бренд пустой, пока что уберем передачу
          position: productPosition,
          list: productsLocation,
        }
      });

      const updatedData = {
        ecommerce: {
          currencyCode: 'RUB',
          impressions: {
            products: newProducts,
          },
        }
      };

      // if (dataLayerIndex !== -1) {
      //   const existingProducts = window.dataLayer[dataLayerIndex].ecommerce.impressions.products;
      //   updatedData.ecommerce.impressions.products = [...existingProducts, ...newProducts];
      //   window.dataLayer[dataLayerIndex] = updatedData;
      // } else {
        window.dataLayer.push(updatedData);
      // }
    }
  }

  function addEcommercePurchase (actionFieldId: string, productsList: IProduct[]) {
    if (window.dataLayer) {
      const newProducts = productsList.map((product) => ({
        id: product?.id || '',
        name: product?.name || '',
        price: product?.price || '',
        brand: product?.brand?.name || '',
        quantity: product?.quantity || ''
      }));

      const ecommerce = {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: actionFieldId
          },
          products: newProducts
        },
      };

      window.dataLayer.push({ ecommerce });
    }
  }

  return {
    addEcommercePurchase,
    addEcommerce,
    addEcommerceProductsList
  };
}
